<template>
  <div class="login_wrap" v-if="isLogin">
    <div class="loginHeaderBox">
      <img class="loginHeader" src="@/assets/img/loginHeader.png" alt="" srcset="">
    </div>
    <div class="form_wrap_admin_box">
      <div class="loginTitle">欢迎登录图南大中台</div>
      <div class="form_wrap_admin">
        <div class="login_type_box">
          <div :class="['login_type', {login_type_active:loginType == 1}]" @click="changeLoginType(1)">账号密码登录</div>
          <div :class="['login_type', {login_type_active:loginType == 2}]" @click="changeLoginType(2)">飞书扫码登录</div>
        </div>
        <div class="form_box_admin" v-if="loginType == 1">
          <el-form :model="adminPrams" :rules="adminRules" ref="adminFormRef">
            <el-form-item label="" prop="account" style="margin-bottom:25px;">
              <el-input v-model="adminPrams.account" placeholder="请输入账号" @keyup.enter="onLoginAdmin">
                <template #prefix>
                  <el-icon>
                    <UserFilled />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="adminPrams.password" type="password" show-password placeholder="请输入密码" @keyup.enter="onLoginAdmin">
                <template #prefix>
                  <el-icon>
                    <Lock />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="adminPrams.saveMe" label="记住密码" />
            </el-form-item>
            <el-form-item label="">
              <el-button class="login_btn" type="primary" size="large" @click="onLoginAdmin" :loading="adminLoading">登录</el-button>
            </el-form-item>
          </el-form>

        </div>
        <div v-else-if="loginType == 2" style="padding: 2px 20px;min-width: 300px;min-height: 304px;position: relative;" v-loading="qrCodeLoading">
          <div id="feishu" style="text-align: center;"></div>
          <div style="
            position: absolute;
            left: 0;
            bottom: 10px;
            text-align: center;
            width: 100%;
            font-size: 14px;
            color: #898d90;
            font-size: 14px;">请使用飞书扫描二维码登录</div>
        </div>
        <!-- <div class="change_login_type" style="margin-top: 14px;" @click="changeLogin">切换至供应商入口</div> -->

      </div>
    </div>
    <div class="el-login-footer">
      <div class="slogan">
        <img class="slogan_img" src="@/assets/img/slogan.gif" alt="" srcset="">
        <div class="slogan_txt">长期主义·创新·ENJOY·死磕·互相成就</div>
      </div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">版权所有 @2021-2025 德拉姆图南大中台. All Rights Reserved.
          粤ICP备2021153112号-1</a>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-loading="true" style="height:10vh;margin-top:10vh">
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { nextTick, onMounted, reactive, ref } from "vue";
import { ssoAuth, fsLogin, ssoLogin } from "@/api/login/login";
import { useRouter, useRoute } from "vue-router";
// import { createWebSocket } from "@/utils/socket";
import message from "@/utils/message";
export default {
  name: "Login",
  setup() {
    const mapping = {
      "test.sso.idelamu.com": "https://test.idelamu.com",
      "prepub.sso.idelamu.com": "https://prepub.idelamu.com",
      "sso.idelamu.com": "https://erp.idelamu.com",
    };
    const route = useRoute();
    const isLogin = ref(false);
    const qrCodeLoading = ref(false);
    // const isAdmin = ref(false);
    const store = useStore();
    const router = useRouter();
    // const formRef = ref(null);
    const adminFormRef = ref(null);
    const loading = ref(false);
    const loginType = ref(2);
    const adminLoading = ref(false);

    const rules = {
      suppName: [
        { required: true, message: "请输入账号", trigger: ["blur", "change"] },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: ["blur", "change"] },
      ],
    };
    const adminRules = {
      account: [
        { required: true, message: "请输入账号", trigger: ["blur", "change"] },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: ["blur", "change"] },
      ],
    };

    // const params = reactive({
    //   suppName: "",
    //   account: "admin",
    //   password: "",
    //   saveMe: false,
    //   // systemCode:"ERP" //SUPP_COOPERATE
    // });
    const adminPrams = reactive({
      account: "",
      password: "",
      saveMe: false,
      // systemCode:"ERP" //SUPP_COOPERATE
    });

    // const onLogin = () => {
    //   formRef.value.validate((valid) => {
    //     if (valid) {
    //       loading.value = true;
    //       login(params).then((res) => {
    //         if (res.resCode == 0) {
    //           store.commit("clearTagsRouter");
    //           getRouterList();
    //           getUserInfo().then((res) => {
    //             if (res.resCode == 0) {
    //               store.commit("userInfo", res.data);
    //             }
    //           });
    //           createWebSocket();
    //           if (params.saveMe) {
    //             localStorage.setItem("saveMe", JSON.stringify(params));
    //           } else {
    //             localStorage.removeItem("saveMe");
    //           }
    //           router.replace({
    //             path: "/",
    //           });
    //         }
    //         loading.value = false;
    //       });
    //     }
    //   });
    // };

    // const changeLogin = () => {
    //   isAdmin.value = !isAdmin.value;
    //   loginType.value = 2;
    //   nextTick(() => {
    //     initFeishu();
    //   });
    // };

    const initFeishu = () => {
      let origin =
        process.env.NODE_ENV == "development"
          ? "https://test.supp.idelamu.com"
          : location.origin;
      // var url = location.href; //origin + location.pathname;
      var url = origin + location.pathname;
      let state = route.query.state;
      if (!state) {
        let redirect = route.query.redirect;
        let redirect2 = route.query.redirect2;
        state = `feishu,${redirect},${redirect2}`;
      }
      var client_id = "cli_a5ec0cbf79a9500b";
      var goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${client_id}&response_type=code&state=${state}&redirect_uri=${url}`;
      var QRLoginObj = QRLogin({
        id: "feishu",
        goto, //请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "300",
        height: "300",
      });
      qrCodeLoading.value = false;
      var handleMessage = function (event) {
        if (
          QRLoginObj.matchOrigin(event.origin) &&
          QRLoginObj.matchData(event.data)
        ) {
          var loginTmpCode = event.data.tmp_code;
          // 在授权页面地址上拼接上参数 tmp_code，并跳转
          window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    };
    const changeLoginType = (type) => {
      if (loginType.value == type) {
        return;
      }
      loginType.value = type;
      nextTick(() => {
        if (type == 2) {
          initFeishu();
        }
      });
    };
    const codeLoginFeishu = (scanCodeParams) => {
      fsLogin(scanCodeParams)
        .then((res) => {
          if (res) {
            if (res.resCode == 0) {
              let state = route.query.state;
              state = state.split(",");
              let redirect = state[1];
              let redirect2 = state[2];
              loginSuccess(res.data, redirect, redirect2);
              // store.commit("clearTagsRouter");
              // getRouterList();
              // getUserInfo().then((res) => {
              //   if (res.resCode == 0) {
              //     store.commit("userInfo", res.data);
              //   }
              // });
              // router.replace({
              //   path: "/",
              // });
            } else {
              message.error(res.errMsg);
              initFeishu();
            }
          } else {
            initFeishu();
          }
        })
        .catch(() => {
          initFeishu();
        });
    };

    const onLoginAdmin = () => {
      adminFormRef.value.validate((valid) => {
        if (valid) {
          adminLoading.value = true;
          ssoLogin(adminPrams).then((res) => {
            if (res.resCode == 0) {
              if (adminPrams.saveMe) {
                localStorage.setItem("adminSaveMe", JSON.stringify(adminPrams));
              } else {
                localStorage.removeItem("adminSaveMe");
              }
              // let redirect = route.query.redirect;
              // let redirect2 = route.query.redirect2;
              loginSuccess(res.data);
              // store.commit("clearTagsRouter");
              // getRouterList();
              // getUserInfo().then((res) => {
              //   if (res.resCode == 0) {
              //     store.commit("userInfo", res.data);
              //   }
              // });
              // if (adminPrams.saveMe) {
              //   localStorage.setItem("adminSaveMe", JSON.stringify(adminPrams));
              // } else {
              //   localStorage.removeItem("adminSaveMe");
              // }
              // router.replace({
              //   path: "/",
              // });
            } else {
              message.error(res.errMsg);
            }
            adminLoading.value = false;
          });
        }
      });
    };

    const loginSuccess = (ticket, redirectP, redirect2P) => {
      let host = mapping[location.host] || mapping["sso.idelamu.com"];
      let r1 = route.query.redirect || host;
      let r2 = route.query.redirect2 || host + "/loginVerification";
      let redirect = decodeURIComponent(redirectP || r1);
      let redirect2 = decodeURIComponent(redirect2P || r2);
      window.location.href = `${redirect2}?ticket=${ticket}&redirect=${redirect}`;
    };

    const onSsoAuth = (isInitFeishu = true) => {
      ssoAuth().then((res) => {
        // let redirect = route.query.redirect;
        // let redirect2 = route.query.redirect2;
        if (res.resCode == "0") {
          loginSuccess(res.data);
        } else if (isInitFeishu) {
          isLogin.value = true;
          nextTick(() => {
            initFeishu();
          });
        }
      });
    };

    onMounted(() => {
      document.addEventListener("visibilitychange", function () {
        //浏览器tab切换监听事件
        if (document.visibilityState == "visible") {
          //状态判断：显示（切换到当前页面）
          onSsoAuth(false);
        }
      });

      qrCodeLoading.value = true;
      // let saveMe = localStorage.getItem("saveMe");
      // if (saveMe) {
      //   let info = JSON.parse(saveMe);
      //   params.suppName = info.suppName;
      //   params.password = info.password;
      //   params.saveMe = info.saveMe;
      // }
      let adminSaveMe = localStorage.getItem("adminSaveMe");
      if (adminSaveMe) {
        let info = JSON.parse(adminSaveMe);
        adminPrams.account = info.account;
        adminPrams.password = info.password;
        adminPrams.saveMe = info.saveMe;
      }
      let state = route.query.state;
      let code = route.query.code;
      if (state && state.indexOf("feishu") != -1 && code) {
        // isAdmin.value = true;
        isLogin.value = true;
        codeLoginFeishu({ code, isSaveMe: true });
        return;
      } else {
        onSsoAuth();
      }
    });
    return {
      isLogin,
      loading,
      // params,
      rules,
      // formRef,
      // onLogin,
      // changeLogin,
      // isAdmin,
      changeLoginType,
      loginType,
      qrCodeLoading,
      adminPrams,
      adminRules,
      onLoginAdmin,
      adminLoading,
      adminFormRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.login_wrap {
  width: 100vw;
  height: 100vh;
  // background: linear-gradient(20deg, #63ad5c, #39a0ff);
  // background: linear-gradient(rgba(0, 51, 153, 0.1), rgba(0, 51, 153, 0.1)),
  // url("@/assets/img/loginBg.png");
  background-image: url("@/assets/img/loginBackground.jpg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100% 100%;
  .loginHeaderBox {
    height: 58px;
    // background: rgba(220, 231, 242, 0.57);
    display: flex;
    align-items: center;
    .loginHeader {
      margin-left: 38px;
      width: 200px;
      object-fit: contain;
    }
  }
  .change_login {
    position: fixed;
    top: 50px;
    right: 80px;
    color: #004adf;
    cursor: pointer;
    font-weight: bold;
  }
  .title_box {
    text-align: center;
    margin-bottom: 60px;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 16px;
      margin: 20px 0;
    }
  }
  .form_wrap {
    padding-top: 18vh;
    position: relative;
    z-index: 100;
    .form_box {
      margin: 0 auto;
      // padding: 50px 50px 40px;
      width: 420px;
      background-color: white;
      border-radius: 6px;
    }
    .login_btn {
      width: 100%;
      margin-top: 30px;
    }
  }
  .el-login-footer {
    // height: 40px;
    // line-height: 40px;
    position: absolute;
    bottom: 14px;
    width: 100%;
    text-align: center;
    color: #000000;
    // font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
    white-space: nowrap;
    a {
      color: #000000;
      text-decoration: none;
    }
    .slogan {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
      .slogan_txt{
        padding-bottom: 14px;
      }
      .slogan_img{
        width: 92px;
        // height: 100px;
        // margin: 0 auto;
        display: block;
      }
    }
  }
  .form_wrap_admin_box {
    position: fixed;
    top: 45%;
    right: 14%;
    transform: translateY(-50%);

    .loginTitle {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    .form_wrap_admin {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      // left: 50%;
      // transform: translateX(-50%);
      background-color: white;
      border-radius: 6px;
      overflow: hidden;
      .login_type_box {
        display: flex;

        .login_type {
          padding: 20px 0;
          flex: 1;
          text-align: center;
          cursor: pointer;
          background-color: #f2f6fc;
          font-weight: bold;
        }
        .login_type_active {
          color: #004adf;
          background-color: #ffffff;
        }
      }
      .form_box_admin {
        padding: 42px 30px 20px;
        width: 280px;
      }
    }
  }
}
.login_btn {
  width: 100%;
  margin-top: 32px;
}
.change_login_type {
  text-align: center;
  color: #004adf;
  border-top: 1px solid #f2f2f2;
  padding: 13px 0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>